import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Container from "../components/Container"
import BgImg from "../components/BgImg"

const Values = props => {
  const values = props.data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Our Values" />
      <Container
        title="Our Values"
        style={{
          background: `#52575a`,
          color: `white`,
        }}
        whitetitle
      >
        {values.map(({ node }, index) => {
          const picture = node.frontmatter.picture.childImageSharp.fluid
          const body = node.rawMarkdownBody

          return (
            <div style={{ zIndex: 10 }} key={index}>
              <Img
                fluid={picture}
                alt="Courtyard"
                style={{
                  display: "flex",
                  maxWidth: "900px",
                  height: "40vh",
                  width: "100%",
                }}
              />
              <p style={{ maxWidth: "900px" }}>{body}</p>
            </div>
          )
        })}
        <BgImg />
      </Container>
    </Layout>
  )
}

export default Values

export const valuesQuery = graphql`
  query valuesQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "values" } } }
    ) {
      edges {
        node {
          frontmatter {
            picture {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          rawMarkdownBody
        }
      }
    }
  }
`
